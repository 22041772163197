import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonExternal = makeShortcode("ButtonExternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "das-bed-and-breakfast-für-einen-besuch-in-nancy-und-metz"
    }}>{`Das `}<strong parentName="h1">{`Bed and Breakfast`}</strong>{` für einen Besuch in `}<strong parentName="h1">{`Nancy`}</strong>{` und `}<strong parentName="h1">{`Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGqJWxM48P/xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIDEhEhI//aAAgBAQABBQJ5L2y1yJDt0bdeZ//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECESEyM0GR/9oACAEBAAY/Ati2dIzRmMWcS9P/xAAeEAEAAgAHAQAAAAAAAAAAAAABABEhMUFRYXGB0f/aAAgBAQABPyG+EVe0vupmRUt4LYBgXkogA6l/BH//2gAMAwEAAgADAAAAEAjv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EENpf//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8Q0oys/8QAGxABAQADAAMAAAAAAAAAAAAAAREAITFBYXH/2gAIAQEAAT8QAjnmTjchuxpdOsFoTsbJkS94XcyQ46M37HK9B9hn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/f994e/image.webp 163w", "/static/28e4fc40eb91a87992ead592da6b2869/bcd70/image.webp 325w", "/static/28e4fc40eb91a87992ead592da6b2869/5d8d7/image.webp 650w", "/static/28e4fc40eb91a87992ead592da6b2869/6c7d1/image.webp 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/367e5/image.jpg 163w", "/static/28e4fc40eb91a87992ead592da6b2869/ab07c/image.jpg 325w", "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg 650w", "/static/28e4fc40eb91a87992ead592da6b2869/1fe05/image.jpg 960w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/28e4fc40eb91a87992ead592da6b2869/ebabe/image.jpg",
              "alt": "authentisches französisches Bed and Breakfast zu Besuch in Frankreich",
              "title": "authentisches französisches Bed and Breakfast zu Besuch in Frankreich",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Bed and breakfast`}</strong></p>
    <h2 {...{
      "id": "eine-romantische-nacht-in-einem-lothringischen-schloss-"
    }}>{`Eine `}<strong parentName="h2">{`romantische Nacht`}</strong>{` in einem `}<strong parentName="h2">{`lothringischen Schloss`}</strong>{` ?`}</h2>
    <h3 {...{
      "id": "ruhe-und-gelassenheit-versichert--ein-paar-minuten-von-nancy"
    }}>{`Ruhe und Gelassenheit versichert … Ein paar Minuten von `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`Wir sind seit 25 Jahren als `}<strong parentName="p">{`Bett und Frühstück`}</strong>{` tätig. Es ist uns eine große Freude, jedes Jahr mehr als 5.000 Reisende bei uns begrüßen zu dürfen.`}</p>
    <p>{`Wir heißen Sie willkommen, damit Sie Lothringen entdecken, sich einen Moment der Romantik gönnen, dem Alltag entfliehen können, oder anderes, mit Begeisterung lassen wir Sie Ihre Freuden verwirklichen.`}</p>
    <p>{`Es ist wichtig, dass das Château de Morey Ihnen Komfort und Ruhe verspricht. Unsere Zimmer sind alle perfekt isoliert und mit sichtbaren Steinmauern ausgestattet. Sie alle gewährleisten einen außergewöhnlichen Komfort mit hochwertigen Bettwaren. Alle unsere Zimmer haben auch die Besonderheit, sehr geräumig zu sein.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Reservieren</ButtonExternal>
    <h2 {...{
      "id": "für-ihre-intimen-nächte-in-lothringen"
    }}>{`Für Ihre intimen Nächte in `}<strong parentName="h2">{`Lothringen`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABd2IxndIjg//EABoQAAIDAQEAAAAAAAAAAAAAAAARAQISAyH/2gAIAQEAAQUC1SReM5yydDsf/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8BuGP/xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhEiAx/9oACAEBAAY/App6Tk7On//EABoQAAMBAAMAAAAAAAAAAAAAAAABESExYbH/2gAIAQEAAT8hw33Dq7no4ZQskeDpmzDQ+Z//2gAMAwEAAgADAAAAEFcP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxAScsb/xAAWEQEBAQAAAAAAAAAAAAAAAAABECH/2gAIAQIBAT8QQdj/xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhUTFBcWGh/9oACAEBAAE/EEYgGrpR7cpHgzV7d7lwXf07lvAGBye5idRkaAOP2Vgl/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/f994e/tourelle2.webp 163w", "/static/e5f290bd73f3916fcf58131988aa1728/bcd70/tourelle2.webp 325w", "/static/e5f290bd73f3916fcf58131988aa1728/5d8d7/tourelle2.webp 650w", "/static/e5f290bd73f3916fcf58131988aa1728/c4443/tourelle2.webp 975w", "/static/e5f290bd73f3916fcf58131988aa1728/5cc32/tourelle2.webp 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/1dd8b/tourelle2.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/367e5/tourelle2.jpg 163w", "/static/e5f290bd73f3916fcf58131988aa1728/ab07c/tourelle2.jpg 325w", "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg 650w", "/static/e5f290bd73f3916fcf58131988aa1728/cdb69/tourelle2.jpg 975w", "/static/e5f290bd73f3916fcf58131988aa1728/2616f/tourelle2.jpg 1300w", "/static/e5f290bd73f3916fcf58131988aa1728/a4af9/tourelle2.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e5f290bd73f3916fcf58131988aa1728/ebabe/tourelle2.jpg",
              "alt": "Bett und Frühstück Nancy",
              "title": "Bett und Frühstück Nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`bed and breakfast close to Nancy`}</strong></p>
    <h3 {...{
      "id": "dieses-brautzimmer-im-herzen-der-region-meurthe-et-moselle-verspricht-ihnen-einen-romantischen-aufenthalt"
    }}>{`Dieses Brautzimmer im Herzen der Region `}<strong parentName="h3">{`Meurthe et Moselle`}</strong>{` verspricht Ihnen einen romantischen Aufenthalt.`}</h3>
    <h4 {...{
      "id": "die-tourelle-für-die-unzertrennlichen-im-bed-and-breakfast"
    }}>{`Die Tourelle für die Unzertrennlichen im Bed and Breakfast`}</h4>
    <p>{`Dieser Raum befindet sich in einem der Türme des Schlosses. Der Charme seiner freigelegten Steine versetzt Sie in eine mittelalterliche Atmosphäre. Verbringen Sie Ihre Nacht in einem Himmelbett von 180 m, Komfort und Ruhe sind garantiert.`}</p>
    <p>{`Genießen Sie einen Blick auf unseren großen Wald und auf den Reitweg des Schlosses.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Diesen Raum buchen</ButtonExternal>
    <h2 {...{
      "id": "der-liebling-der-chatelaine-lorraine"
    }}>{`Der Liebling der Chatelaine `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABn0tWJYSB3//EABgQAAMBAQAAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFAgtJA4edYTi507//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BSf/EABkQAAIDAQAAAAAAAAAAAAAAAAABECExcf/aAAgBAQAGPwLg4Vms0//EABwQAQEBAAIDAQAAAAAAAAAAAAERADFRIUFhkf/aAAgBAQABPyE6IUWarDYXrdQ/chh8ZPLevLn7b//aAAwDAQACAAMAAAAQcP8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxBLwh//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EIaVH//EAB0QAQEAAgEFAAAAAAAAAAAAAAERACExQVFhgdH/2gAIAQEAAT8QkqTAgohswEIMiMJ464LhE1qLnKdS+0+42AGls1ZMBRDO1z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/f994e/chambres-dhotes-airbnb-nancy.webp 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/bcd70/chambres-dhotes-airbnb-nancy.webp 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5d8d7/chambres-dhotes-airbnb-nancy.webp 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/c4443/chambres-dhotes-airbnb-nancy.webp 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/5cc32/chambres-dhotes-airbnb-nancy.webp 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/1dd8b/chambres-dhotes-airbnb-nancy.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/367e5/chambres-dhotes-airbnb-nancy.jpg 163w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ab07c/chambres-dhotes-airbnb-nancy.jpg 325w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg 650w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/cdb69/chambres-dhotes-airbnb-nancy.jpg 975w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/2616f/chambres-dhotes-airbnb-nancy.jpg 1300w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/a4af9/chambres-dhotes-airbnb-nancy.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/5642ba84ac9673b8d7e3612c6b9d07da/ebabe/chambres-dhotes-airbnb-nancy.jpg",
              "alt": "übernachtung und frühstück in der nähe von metz",
              "title": "übernachtung und frühstück in der nähe von metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`übernachtung und frühstück in der nähe von metz`}</strong></p>
    <h3 {...{
      "id": "eine-nacht-in-der-nähe-von-nancy-in-einem-prinzessinnenzimmer"
    }}>{`Eine Nacht in der Nähe von Nancy in einem Prinzessinnenzimmer`}</h3>
    <p>{`Ein geräumiges Zimmer von 64m2 und sein Himmelbett lassen Sie eine Nacht von König und Königin erleben. Genießen Sie eine große italienische Dusche und einen Lounge-Bereich zum Entspannen. Eine überwältigende Aussicht auf das Tal der Natagne mit seinen lothringischen Farbskizzen.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Reservieren</ButtonExternal>
    <h2 {...{
      "id": "ein-familienmoment-in-der-familiensuite-für-einen-aufenthalt-in-nancy-"
    }}>{`Ein Familienmoment in der Familiensuite für einen Aufenthalt in `}<strong parentName="h2">{`Nancy`}</strong>{` ?`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABOzI0C0QJD//EABkQAAIDAQAAAAAAAAAAAAAAAAERAAIDEv/aAAgBAQABBQJqZ3dmDAHMTxDfk//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGsf//EABURAQEAAAAAAAAAAAAAAAAAAAIQ/9oACAECAQE/ATP/xAAZEAEAAgMAAAAAAAAAAAAAAAAAARARITL/2gAIAQEABj8CYdVpL//EABoQAQEBAQEBAQAAAAAAAAAAAAERADEhQZH/2gAIAQEAAT8hfB7dCQeHdS0iMl13XfA/cogJbv/aAAwDAQACAAMAAAAQU/8A/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxCOLP/EABYRAQEBAAAAAAAAAAAAAAAAABEBAP/aAAgBAgEBPxAWmo7/xAAbEAEBAQEAAwEAAAAAAAAAAAABEQAhMUFRYf/aAAgBAQABPxBDafhMhp9pAn7lnbAcGmMn6PdEgojTq81YdAnjf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/f994e/suite.webp 163w", "/static/c3536ba51be167d9b8df18ef93761b14/bcd70/suite.webp 325w", "/static/c3536ba51be167d9b8df18ef93761b14/5d8d7/suite.webp 650w", "/static/c3536ba51be167d9b8df18ef93761b14/c4443/suite.webp 975w", "/static/c3536ba51be167d9b8df18ef93761b14/5cc32/suite.webp 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/1dd8b/suite.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/367e5/suite.jpg 163w", "/static/c3536ba51be167d9b8df18ef93761b14/ab07c/suite.jpg 325w", "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg 650w", "/static/c3536ba51be167d9b8df18ef93761b14/cdb69/suite.jpg 975w", "/static/c3536ba51be167d9b8df18ef93761b14/2616f/suite.jpg 1300w", "/static/c3536ba51be167d9b8df18ef93761b14/a4af9/suite.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c3536ba51be167d9b8df18ef93761b14/ebabe/suite.jpg",
              "alt": "übernachtung und frühstück in der nähe von nancy",
              "title": "übernachtung und frühstück in der nähe von nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`übernachtung und frühstück in der nähe von nancy`}</strong></p>
    <h3 {...{
      "id": "genießen-sie-ihre-zeit-mit-ihrer-familie-in-lothringen-in-der-nähe-von-nancy-und-metz"
    }}>{`Genießen Sie Ihre Zeit mit Ihrer Familie in `}<strong parentName="h3">{`Lothringen`}</strong>{` in der Nähe von `}<strong parentName="h3">{`Nancy`}</strong>{` und `}<strong parentName="h3">{`Metz`}</strong>{`.`}</h3>
    <p>{`Das Schloss heißt Sie als Paar und als Familie willkommen, indem es ein majestätisches Zimmer für Ihr Paar und ein Nebenzimmer für Ihre Kinder zur Verfügung stellt.`}</p>
    <p>{`Sie werden ein geräumiges Badezimmer und einen atemberaubenden Blick auf die romanische Kirche aus dem XII. Jahrhundert und das große Lothringer Tal genießen.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Reservieren</ButtonExternal>
    <h2 {...{
      "id": "sweet-printanière-sweet-lorraine"
    }}>{`Sweet Printanière, Sweet `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdMTG875kcT/xAAbEAABBAMAAAAAAAAAAAAAAAABAAIREgMEE//aAAgBAQABBQJqsazVY3kHYjiv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/Aa1//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAECEf/aAAgBAgEBPwGsMf/EABoQAQACAwEAAAAAAAAAAAAAAAABMRESQSH/2gAIAQEABj8CR5bDXiOLf//EABsQAQACAgMAAAAAAAAAAAAAAAEAESFBMWGh/9oACAEBAAE/IXQoiHcW4Aqu5VZeze0GwFl1MubT/9oADAMBAAIAAwAAABBr/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAh/9oACAEDAQE/EGcXNNm//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIWH/2gAIAQIBAT8QoakWz//EABsQAQEBAQADAQAAAAAAAAAAAAERACExQVGR/9oACAEBAAE/EIy5ZGKgDU6/MrIEXnGVEK+E9ORxR9QCfM3qm//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/f994e/printaniere.webp 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/bcd70/printaniere.webp 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/5d8d7/printaniere.webp 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/c4443/printaniere.webp 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/5cc32/printaniere.webp 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/1dd8b/printaniere.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/367e5/printaniere.jpg 163w", "/static/1cf0c9321a39b0681be39d8ea400489d/ab07c/printaniere.jpg 325w", "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg 650w", "/static/1cf0c9321a39b0681be39d8ea400489d/cdb69/printaniere.jpg 975w", "/static/1cf0c9321a39b0681be39d8ea400489d/2616f/printaniere.jpg 1300w", "/static/1cf0c9321a39b0681be39d8ea400489d/a4af9/printaniere.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1cf0c9321a39b0681be39d8ea400489d/ebabe/printaniere.jpg",
              "alt": "übernachtung und frühstück",
              "title": "übernachtung und frühstück",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`übernachtung und frühstück`}</strong></p>
    <h3 {...{
      "id": "ein-aufenthalt-in-einem-gästezimmer-mit-der-warmen-farbe-der-schönen-tage"
    }}>{`Ein Aufenthalt in einem Gästezimmer mit der warmen Farbe der schönen Tage`}</h3>
    <p>{`Entspannen Sie sich in diesem Sonnenzimmer mit seiner Dekoration, die ihm ein toskanisches Aussehen verleiht. Sie werden seinen Panoramablick auf den Schlosspark und das Tal von Meurthe et Moselle zu schätzen wissen. Ein großes Badezimmer mit einer geräumigen Sitzecke erlaubt Ihnen, sich während Ihrer Reise zwischen Nancy und Metz zu entspannen.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Reservieren</ButtonExternal>
    <h2 {...{
      "id": "flying-in-the-colvert"
    }}>{`Flying in the Colvert`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAAB79YoOsnrf//EABkQAAMBAQEAAAAAAAAAAAAAAAECEQATEv/aAAgBAQABBQKws4U+rurDdC7wjf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQIxIf/aAAgBAQAGPwKEhc8L0zUjzTR//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8htmuDHpgTYPxC9mj33HKB8nEI8J//2gAMAwEAAgADAAAAEMjP/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIVH/2gAIAQMBAT8QdbSZyf/EABgRAAIDAAAAAAAAAAAAAAAAAAAxARFh/9oACAECAQE/EITK0//EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhYZH/2gAIAQEAAT8QeS4ovvWFkcun2Gk7HERVuFDbT2LBEorSG1ceA/Eqf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/f994e/colvert.webp 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/bcd70/colvert.webp 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5d8d7/colvert.webp 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/c4443/colvert.webp 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/5cc32/colvert.webp 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/1dd8b/colvert.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/367e5/colvert.jpg 163w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ab07c/colvert.jpg 325w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg 650w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/cdb69/colvert.jpg 975w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/2616f/colvert.jpg 1300w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a4af9/colvert.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/ebabe/colvert.jpg",
              "alt": "chambres d’hôtes",
              "title": "chambres d’hôtes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes`}</strong></p>
    <h3 {...{
      "id": "the-symbol-of-lorraine-will-transport-you-to-an-authentic-moment"
    }}>{`The symbol of `}<strong parentName="h3">{`Lorraine`}</strong>{` will transport you to an authentic moment.`}</h3>
    <p>{`Gönnen Sie sich eine Nacht in einem 43m2 großen Zimmer mit einem Doppelbett von 200x200. Sie finden eine Badewanne zum Entspannen. Von diesem Zimmer aus können Sie auch die Aussicht auf das Tal und den Schlosspark genießen. Genießen Sie den Charme dieses Zimmers, der die Authentizität Lothringens widerspiegelt.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Reservieren</ButtonExternal>
    <h2 {...{
      "id": "fliegen-im-colvert"
    }}>{`Fliegen im Colvert`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAFuY7OkQIf/xAAbEAACAgMBAAAAAAAAAAAAAAABAgAhAxESMv/aAAgBAQABBQJHUx3o3FTiH2cp3//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ASf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAEQETFRYYH/2gAIAQEABj8Ceh0jJ0sj/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARMSFBUZH/2gAIAQEAAT8hvw+jKF8sl1Yn2C9Uuzmm47Nh5//aAAwDAQACAAMAAAAQpD//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QBa3/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QTYX/xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMUFhof/aAAgBAQABPxAkopp5kkoiMN07mIMNDqYGCirU3clIDSDakj94ygqO9jrznP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/f994e/pdj.webp 163w", "/static/ba3ef274c3ef583c5369d5411d433412/bcd70/pdj.webp 325w", "/static/ba3ef274c3ef583c5369d5411d433412/5d8d7/pdj.webp 650w", "/static/ba3ef274c3ef583c5369d5411d433412/c4443/pdj.webp 975w", "/static/ba3ef274c3ef583c5369d5411d433412/5cc32/pdj.webp 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/1dd8b/pdj.webp 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/367e5/pdj.jpg 163w", "/static/ba3ef274c3ef583c5369d5411d433412/ab07c/pdj.jpg 325w", "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg 650w", "/static/ba3ef274c3ef583c5369d5411d433412/cdb69/pdj.jpg 975w", "/static/ba3ef274c3ef583c5369d5411d433412/2616f/pdj.jpg 1300w", "/static/ba3ef274c3ef583c5369d5411d433412/a4af9/pdj.jpg 2302w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/ba3ef274c3ef583c5369d5411d433412/ebabe/pdj.jpg",
              "alt": "breakfast",
              "title": "breakfast",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`breakfast and relax`}</strong></p>
    <h3 {...{
      "id": "das-symbol-lothringens-wird-sie-in-einen-authentischen-moment-versetzen"
    }}>{`Das Symbol Lothringens wird Sie in einen authentischen Moment versetzen.`}</h3>
    <p>{`Hier ist die Formel Frühstück:`}</p>
    <ul>
      <li parentName="ul">{`Heiße Getränke ☕`}</li>
      <li parentName="ul">{`Wiener Mehlspeisen 🍞`}</li>
      <li parentName="ul">{`Bio-Orangensaft 🍊`}</li>
      <li parentName="ul">{`Joghurt und Obst 🍶🍐🍏`}</li>
      <li parentName="ul">{`Sie würden dieses kostenlose Frühstück, das aus lokalen und lothringischen Produkten besteht, zu schätzen wissen.`}</li>
    </ul>
    <h2 {...{
      "id": "kleine-details-werden-oft-geschätzt"
    }}>{`Kleine Details werden oft geschätzt`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7FxSpAk//8QAGRAAAwADAAAAAAAAAAAAAAAAAAERAhAh/9oACAEBAAEFAlRZc1CH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABkQAAIDAQAAAAAAAAAAAAAAAAABESAxUf/aAAgBAQAGPwJyPtNZ/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERIUFRcWH/2gAIAQEAAT8hdNOBnRRbI9GJaOH/2gAMAwEAAgADAAAAEITv/8QAFxEBAAMAAAAAAAAAAAAAAAAAACFBUf/aAAgBAwEBPxCk4//EABgRAAIDAAAAAAAAAAAAAAAAAAARASFB/9oACAECAQE/EFY8Qf/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExYUFxgf/aAAgBAQABPxBBo18EKIilVzMiiLzVPuNwLUeGU7ZnUU46ion/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "Übernachtung mit Frühstück zwischen Nancy und Metz",
              "title": "Übernachtung mit Frühstück zwischen Nancy und Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Übernachtung mit Frühstück zwischen Nancy und Metz`}</strong></p>
    <p>{`Während Ihres Aufenthalts zwischen Nancy und Metz wird es Ihnen an nichts fehlen.
Wir wissen, dass es darauf ankommt, die Ausstattung unserer Zimmer ist dazu da, Ihre Reise in Meurthe et Moselle, Lothringen oder Nancy zu perfektionieren. In allen unseren Zimmern finden Sie :`}</p>
    <ul>
      <li parentName="ul">{`Wifi access 🌐`}</li>
      <li parentName="ul">{`Haartrockner 💇♀`}</li>
      <li parentName="ul">{`Duschgel & Shampoo 🚿`}</li>
      <li parentName="ul">{`Handtücher & Bademäntel 💆♀`}</li>
      <li parentName="ul">{`Dokumentation über Lothringen 📜`}</li>
      <li parentName="ul">{`Lounge-Bereich 🛋`}</li>
      <li parentName="ul">{`Bewundern Sie Ihren Moment in der Nähe von Nancy und Metz mit vielfältigen Aktivitäten in unserer Einrichtung, wie SPA, Massage, Schwimmbad, Reiten…`}</li>
    </ul>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Reservieren</ButtonExternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      